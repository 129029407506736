<template>
	<main>
		<HeaderTab :title="$t('global.titre_horse_search')"  :swiper_tabs="swiper_tabs"/>

		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div>
                        <router-view></router-view>
                    	</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script type="text/javascript">

import Navigation from "@/mixins/Navigation.js";

export default {
	name: "HorseSearch",
	mixins: [Navigation],
	data () {
		return {
			param_tab: '',
			actual_tab: 'qualif',
			swiper_tabs: [],
		}
	},
	mounted() {
            this.param_tab = this.$route.params.actual_tab

			this.swiper_tabs = [
				{
					id: 'qualif',
					label: 'horse.search_qualif',
					active: true,
					href : 'SearchQualification'
				},
				{
					id: 'horseWithoutAct',
					label: 'horse.without_act',
					active: false,
					href : 'horseWithoutAct'
				},
				{
					id: 'horseWithoutPension',
					label: 'horse.without_pension',
					active: false,
					href : 'horseWithoutPension'
				}
			]
		},
	methods: {
	},
	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
		SearchQualification : () => import('@/components/Qualification/SearchQualification'),
	}
}
</script>
